
































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/api';
import { AppState } from '@/store/modules/app/types';
import {
  ScenariosAction,
  ScenariosActionType,
  ScenariosGetter,
  ScenariosGetterType,
  ScenariosState
} from '@/store/modules/scenarios/types';
import { GroupsAction, GroupsActionType, GroupsState } from '@/store/modules/groups/types';
import { sort } from '@/utils/sort';
import { nameFilter } from '@/utils/name_filter';
import AssignAllCheckbox from '../AssignAllElements/AssignAllCheckbox/AssignAllCheckbox.vue';
import AssignAllModal from '../AssignAllElements/AssignAllModal/AssignAllModal.vue';

const scenarios = namespace('scenarios');
const groups = namespace('groups');
const app = namespace('app');

@Component({
  components: {
    AssignAllCheckbox,
    AssignAllModal
  }
})
export default class AssignTableGroups extends Vue {
  @Prop(String) readonly searchPhrase!: string

  @Prop(Boolean) readonly permissionWrite!: boolean

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @groups.State
  groups!: GroupsState['groups']

  @scenarios.State
  scenarios!: ScenariosState['scenarios']

  @scenarios.State
  scenarioElements!: ScenariosState['scenarioElements'];

  @scenarios.State
  selectedScenarioID!: ScenariosState['selectedScenarioID'];

  @scenarios.State
  selectedScenarioUuid!: ScenariosState['selectedScenarioUuid'];

  @scenarios.State
  selectedScenarioHasConnections!: ScenariosState['selectedScenarioHasConnections'];

  @scenarios.Getter(ScenariosGetter.GetScenario)
  getScenario!: ScenariosGetterType['GET_SCENARIO'];

  @scenarios.Getter(ScenariosGetter.GetScenarioById)
  getScenarioById!: ScenariosGetterType['GET_SCENARIO_BY_ID'];

  @groups.Action(GroupsAction.AddGroupToScenario)
  addGroupToScenario!: GroupsActionType['ADD_GROUP_TO_SCENARIO'];

  @scenarios.Action(ScenariosAction.SetSelectedScenarioHasConnections)
  SetSelectedScenarioHasConnections!: ScenariosActionType['SET_SELECTED_SCENARIO_HAS_CONNECTIONS'];

  featureReady: boolean = false;

  allChecked: boolean = false;

  allUnchecked: boolean = false;

  intervalID: any = null;

  dialog: boolean = false;

  modalAssignAll: boolean | null = null;

  get isDefaultScenario() {
    const isDefaultUuid = this.scenarios.find((s) => s.is_default)?.uuid;
    if (this.selectedScenarioUuid === isDefaultUuid) return true;
    return false;
  }

  get sortedGroups() {
    let result = this.groups.sort((a, b) => sort(a.name, b.name));
    result = result.sort((a, b) => {
      if (a.scenario_id === this.selectedScenarioID && b.scenario_id !== this.selectedScenarioID) {
        return -1;
      }
      return 1;
    });
    return result.filter((g) => nameFilter(g.name, this.searchPhrase));
  }

  openModal(checkAllSelected: boolean) {
    this.modalAssignAll = checkAllSelected;
    this.dialog = true;
  }

  discardSubmit() {
    this.dialog = false;
  }

  async confirmSubmit(assignAll: boolean) {
    this.dialog = false;
    await this.addAllToScenario(assignAll);
    this.clearAllCheckbox();
  }

  scenarioName(group: Models.Groups.Model) {
    const { scenario_id } = group;
    if (!scenario_id) {
      return '';
    }
    const { name } = this.getScenario(scenario_id) ?? { name: '' };
    return name;
  }

  belongsToSelectedScenario(group: Models.Groups.Model) {
    if (group.scenario_id === this.selectedScenarioUuid) {
      if (!this.selectedScenarioHasConnections) this.SetSelectedScenarioHasConnections(true);
      return true;
    }
    return false;
  }

  checkAll() {
    clearInterval(this.intervalID);
    this.intervalID = setInterval(() => { this.allChecked = !this.allChecked; }, 700);
  }

  uncheckAll() {
    this.allChecked = false;
    clearInterval(this.intervalID);
    this.intervalID = setInterval(() => { this.allUnchecked = !this.allUnchecked; }, 700);
  }

  clearAllCheckbox() {
    clearInterval(this.intervalID);
    this.intervalID = null;
    this.allChecked = false;
    this.allUnchecked = false;
  }

  addAllToScenario(assignAll: boolean) {
    const groupsToAssign = this.sortedGroups.filter(
      (g: Models.Groups.Model) => (
        assignAll ? !this.belongsToSelectedScenario(g) : this.belongsToSelectedScenario(g))
    );
    console.log(
      `With this action you would ${
        assignAll
          ? 'assign'
          : 'unassign'
      } following groups:`, groupsToAssign
    );
    this.clearAllCheckbox();
  }

  addToScenario(group: Models.Groups.Model, switchLoader: () => void) {
    if (this.isDefaultScenario) return;
    switchLoader();
    const isDefaultUuid = this.scenarios.find((s) => s.is_default)?.uuid;
    const scenarioUuidToBeAssigned = this.belongsToSelectedScenario(group) ? isDefaultUuid : this.selectedScenarioUuid;
    const scenarioToBeAssigned = scenarioUuidToBeAssigned ? this.getScenario(scenarioUuidToBeAssigned) : null;

    const selectedScenarioName = this.scenarioName(group);
    const scenarioName = scenarioToBeAssigned?.is_default ? selectedScenarioName : scenarioToBeAssigned?.name;
    const modifiedGroup = {
      ...group,
      scenario_id: scenarioUuidToBeAssigned
    };
    const assigningToSelectedScenario = scenarioUuidToBeAssigned === this.selectedScenarioUuid;

    this.addGroupToScenario({
      group: modifiedGroup,
      scenarioName: scenarioName ?? null,
      assignToDefault: scenarioToBeAssigned?.is_default ?? true
    })
      .then(() => {
        this.SetSelectedScenarioHasConnections(assigningToSelectedScenario);
      })
      .finally(() => {
        switchLoader();
      });
  }
}
