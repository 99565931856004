
















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppState } from '@/store/modules/app/types';
import PlanningCardScenariosTab from './PlanningCardScenariosTab/PlanningCardScenariosTab.vue';
import { GraphsAction, GraphsActionType } from '@/store/modules/graphs/types';

const app = namespace('app');
const graphs = namespace('graphs');

@Component({
  components: {
    PlanningCardScenariosTab,
  },
})
/**
 * @group Planning Card
 * Planning tab content
 */
export default class PlanningCard extends Vue {
  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @graphs.Action(GraphsAction.FetchGraphs)
  fetchGraphs!: GraphsActionType['FETCH_GRAPHS'];

  async mounted() {
    await this.fetchGraphs();
  }
}
