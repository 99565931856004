var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-editable-list-item",class:{
    'c-editable-list-item--edit': _vm.editState,
    'c-editable-list-item--selected': _vm.isSelected,
  }},[_c('BaseTooltip',{attrs:{"position":"top","content":!_vm.editState ? _vm.scenario.name : _vm.$t('tooltips.nameUniqueAndNotEmpty'),"disabled":!_vm.showNameTooltip && _vm.editState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({staticClass:"c-editable-list-item__input"},on),[_c('BaseTextField',{key:_vm.elementKey,attrs:{"value":_vm.scenarioData.name,"disabled":!_vm.editState,"maxlength":35},on:{"change":function($event){_vm.scenarioData.name = $event}}})],1)]}}])}),_c('BaseTooltip',{directives:[{name:"show",rawName:"v-show",value:((_vm.permissionCheckEdit && _vm.editState) || _vm.scenario.is_default),expression:"(permissionCheckEdit && editState) || scenario.is_default"}],attrs:{"position":"top","content":_vm._f("capitalize")(_vm.scenario.is_default
      ? _vm.$t('tooltips.scenarioIsDefault')
      : _vm.scenarioData.is_default
      ? _vm.$t('tooltips.scenarioWillBecomeDefault')
      : _vm.$t('tooltips.setScenarioAsDefault')),"open-delay":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('span',_vm._g({class:{
          'c-editable-list-item__action--default-flag': !_vm.scenario.is_default,
          'c-editable-list-item__action--hidden': !_vm.scenario.uuid
        }},on),[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:((_vm.permissionCheckEdit && _vm.editState) || _vm.scenario.is_default),expression:"(permissionCheckEdit && editState) || scenario.is_default"}],attrs:{"color":hover ? 'rgba(237, 134, 17, 0.57)' : 'grey',"disabled":_vm.scenario.is_default || !_vm.scenario.uuid,"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleScenarioDefaultProp.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.isDefaultData ? 'mdi-flag' : hover ? 'mdi-flag' : 'mdi-flag-outline')+" ")])],1)]}}],null,true)})]}}])}),(_vm.editState)?[_c('v-icon',{staticClass:"c-editable-list-item__action",attrs:{"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.accept.apply(null, arguments)}}},[_vm._v("mdi-check")]),_c('v-icon',{staticClass:"c-editable-list-item__action",attrs:{"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.discard.apply(null, arguments)}}},[_vm._v("mdi-close")])]:[(_vm.permissionCheckEdit)?_c('v-icon',{staticClass:"c-editable-list-item__action",attrs:{"disabled":_vm.userEditingScenario || _vm.userActionStatus.status === 'pending',"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.editScenario.apply(null, arguments)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.permissionCheckDelete)?_c('BaseTooltip',{attrs:{"position":"top","content":_vm._f("capitalize")(_vm.scenario.is_default
          ? _vm.$t('tooltips.mustNotDeleteDefaultScenario')
          : _vm.$t('tooltips.deleteElement', { element: _vm.$tc('main.scenario') })),"extra":_vm._f("capitalize")(_vm.scenario.is_default ? _vm.$t('tooltips.setOtherScenarioToDefault') : null),"open-delay":500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[(_vm.permissionCheckDelete)?_c('v-icon',{staticClass:"c-editable-list-item__action",attrs:{"disabled":_vm.userEditingScenario || _vm.userActionStatus.status === 'pending' || _vm.scenario.is_default,"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('modalAction', _vm.scenario)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}}],null,false,281105593)}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }