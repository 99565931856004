
import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    selectedWeekDays: {
      type: Array as PropType<Array<number>>,
      required: true
    }
  },
  render(createElement) {
    const days = [
      { text: this.$t('schedule.weekdays[0][0]'), value: 0 },
      { text: this.$t('schedule.weekdays[1][0]'), value: 1 },
      { text: this.$t('schedule.weekdays[2][0]'), value: 2 },
      { text: this.$t('schedule.weekdays[3][0]'), value: 3 },
      { text: this.$t('schedule.weekdays[4][0]'), value: 4 },
      { text: this.$t('schedule.weekdays[5][0]'), value: 5 },
      { text: this.$t('schedule.weekdays[6][0]'), value: 6 }
    ];
    const weekDayChips = days.map((d) => {
      const isActive = this.selectedWeekDays.includes(d.value);
      return createElement('span', {
        class: [
          'c-weekdays-picker__day-chip',
          { 'c-weekdays-picker__day-chip--active': isActive }
        ],
        on: {
          click: () => {
            const clickHandler = this.$listeners.weekDaySelect as (value: number) => void;
            clickHandler(d.value);
          }
        }
      }, d.text as string);
    });
    return createElement('div', {
      class: 'c-weekdays-picker'
    }, weekDayChips);
  }

});
