





































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AssignAllCheckbox extends Vue {
  @Prop(String) readonly element!: string;

  @Prop() readonly intervalID!: any;

  checkAllSelected: boolean | null = null;

  get checkMultipleMessage() {
    if (this.checkAllSelected) return this.$t('tooltips.clickIconOnTheLeftTo', { action: this.$t('actions.toAssign') });
    if (this.checkAllSelected === false) return this.$t('tooltips.clickIconOnTheLeftTo', { action: this.$t('actions.toDetach') });
    return this.$t('tooltips.assignOrDetachAllAtOnce', { item: this.$tc(`main.${this.element}`, 2) });
  }

  activateAllCheckboxes() {
    if (!this.intervalID) this.checkAll();
    else if (this.checkAllSelected) this.uncheckAll();
    else this.clearAllCheckbox();
  }

  checkAll() {
    this.checkAllSelected = true;
    this.$emit('checkAll');
  }

  uncheckAll() {
    this.checkAllSelected = false;
    this.$emit('uncheckAll');
  }

  clearAllCheckbox() {
    this.checkAllSelected = null;
    this.$emit('clearAllCheckbox');
  }

  addAllToScenario() {
    if (this.checkAllSelected === null) return;
    this.$emit('addAllToScenario', this.checkAllSelected);
  }
}
