var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-circuits-table"},[_c('BaseTable',{attrs:{"tableData":_vm.sortedCircuits,"disabled":!_vm.selectedScenarioID},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('th',{staticClass:"l-circuits-table__th-checkbox"},[(_vm.featureReady && !_vm.isDefaultScenario && _vm.permissionWrite)?_c('AssignAllCheckbox',{attrs:{"intervalID":_vm.intervalID,"element":'circuit'},on:{"checkAll":_vm.checkAll,"uncheckAll":_vm.uncheckAll,"clearAllCheckbox":_vm.clearAllCheckbox,"addAllToScenario":_vm.openModal}}):_vm._e()],1),_c('th',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('main.circuit'))))]),_c('th',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('main.scenario'))))]),_c('th',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('main.group'))))])]},proxy:true},{key:"row",fn:function(ref){
var circuit = ref.data;
return [_c('BaseTooltip',{attrs:{"disabled":!!_vm.scenarioElements.length || !_vm.selectedScenarioID,"position":"top","openDelay":700,"content":_vm.$t('tooltips.cannotAssignToScenario')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('td',_vm._g({},on),[_c('CircularLoader',{attrs:{"width":5,"size":22},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var switchLoader = ref.switchLoader;
return [_c('RoundCheckbox',{class:{ 'l-circuits-table__checkbox--unclickable': _vm.isDefaultScenario },attrs:{"disabled":!_vm.selectedScenarioID
                || !_vm.scenarioElements.length
                || _vm.userActionStatus.status === 'pending'
                || !_vm.permissionWrite
                || !!_vm.intervalID,"isChecked":!_vm.allUnchecked && (_vm.belongsToSelectedScenario(circuit) || _vm.allChecked)},on:{"input":function($event){return _vm.addToScenario(circuit, switchLoader)}}})]}}],null,true)})],1)]}}],null,true)}),_c('td',[_vm._v(_vm._s(circuit.name))]),_c('td',[_vm._v(_vm._s(_vm.scenarioName(circuit)))]),_c('td',[_vm._v(_vm._s(_vm.groupName(circuit)))])]}},{key:"foot",fn:function(){return [_c('td',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t('messages.noDataAvailable'))+" ")])]},proxy:true}])}),_c('AssignAllModal',{attrs:{"dialog":_vm.dialog,"assignAll":_vm.modalAssignAll,"element":'circuit'},on:{"discard":_vm.discardSubmit,"confirm":_vm.confirmSubmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }