




































































import {
  Component, Vue, Prop, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RRule, rrulestr } from 'rrule';
import {
  ScenariosAction, ScenariosActionType, ScenariosGetter, ScenariosGetterType, ScenariosState
} from '@/store/modules/scenarios/types';
import { Models } from '@mtap-smartcity/api';
import { ParsedOptions } from 'rrule/dist/esm/src/types';
import {
  GraphsAction, GraphsActionType, GraphsGetter, GraphsGetterType
} from '@/store/modules/graphs/types';
import { fetchSunriseSunsetRanges } from '@/api/scenarios';
import VerticalDottedTabs, { ITabItem } from './VerticalDottedTabs/VerticalDottedTabs.vue';
import ScenarioElementModalLocation from './ScenarioElementModalLocation/ScenarioElementModalLocation.vue';
import ScenarioElementModalSchedule from './ScenarioElementModalSchedule/ScenarioElementModalSchedule.vue';
import ScenarioElementModalGraph from './ScenarioElementModalGraph/ScenarioElementModalGraph.vue';

const scenarios = namespace('scenarios');
const graphs = namespace('graphs');

enum TabItem {
  Location,
  Schedule,
  Chart
}

export enum EndDateOption {
  Never,
  OnDate,
  AfterRepeat
}

@Component({
  components: {
    VerticalDottedTabs,
    ScenarioElementModalLocation,
    ScenarioElementModalSchedule,
    ScenarioElementModalGraph
  }
})
export default class ScenarioElementModal extends Vue {
  @Prop(Boolean) readonly showModal!: boolean;

  selectedTab = TabItem.Location;

  saveClicked: boolean = false;

  tabs: Array<ITabItem> = [
    {
      name: this.$t('main.location'),
      value: TabItem.Location,
      mdiIcon: 'mdi-map'
    },
    {
      name: this.$t('main.schedule'),
      value: TabItem.Schedule,
      mdiIcon: 'mdi-calendar'
    },
    {
      name: this.$t('main.chart'),
      value: TabItem.Chart,
      mdiIcon: 'mdi-chart-bar'
    },
  ];

  scenarioElementData: Partial<Models.ScenarioElements.Model> = {
    // scenario_id: null,
    // graph_id: null,
    // rrule: null,
    astronomical_control: false
  };

  rruleOptions: Pick<ParsedOptions, 'freq' | 'dtstart' | 'until' | 'bymonth' | 'byweekday' | 'bymonthday' | 'interval' | 'count'> = {
    dtstart: new Date(),
    freq: RRule.DAILY,
    interval: 1,
    until: null,
    bymonth: [],
    byweekday: [],
    bymonthday: [],
    count: null
  };

  graph: { [key in keyof Models.ScenarioGraphs.Model]: any } = {
    changes: Array.from({ length: 96 })
      .map(() => 50)
  };

  coordinates: google.maps.LatLngLiteral | null = null;

  sunriseSunsetRanges: Models.Scenarios.SunriseSunsetRange | null = null;

  @scenarios.State
  selectedScenarioID!: ScenariosState['selectedScenarioID'];

  @scenarios.State
  selectedScenarioUuid!: ScenariosState['selectedScenarioUuid'];

  @scenarios.State
  selectedScenarioElementEditID!: ScenariosState['selectedScenarioElementEditID'];

  @scenarios.Getter(ScenariosGetter.GetScenarioElementById)
  getScenarioElementById!: ScenariosGetterType['GET_SCENARIO_ELEMENT_BY_ID'];

  @scenarios.Action(ScenariosAction.CreateScenarioElement)
  createScenarioElement!: ScenariosActionType['CREATE_SCENARIO_ELEMENT'];

  @scenarios.Action(ScenariosAction.UpdateScenarioElement)
  updateScenarioElement!: ScenariosActionType['UPDATE_SCENARIO_ELEMENT'];

  @scenarios.Action(ScenariosAction.SetSelectedScenarioElementID)
  setSelectedScenarioElementEditID!: ScenariosActionType['SET_SELECTED_SCENARIO_ELEMENT_ID'];

  @scenarios.Action(ScenariosAction.FetchScenarioElementsByScenario)
  fetchScenarioElementsByScenario!: ScenariosActionType['FETCH_SCENARIO_ELEMENTS_BY_SCENARIO'];

  @graphs.Getter(GraphsGetter.GetGraph)
  getGraph!: GraphsGetterType['GET_GRAPH'];

  @graphs.Action(GraphsAction.CreateGraph)
  createGraph!: GraphsActionType['CREATE_GRAPH'];

  @graphs.Action(GraphsAction.UpdateGraph)
  updateGraph!: GraphsActionType['UPDATE_GRAPH'];

  get numberOfOccurences() {
    return this.rruleOptions.count;
  }

  get selectedEndDateOption() {
    if (this.rruleOptions.count) {
      return EndDateOption.AfterRepeat;
    }
    if (this.rruleOptions.until) {
      return EndDateOption.OnDate;
    }
    return EndDateOption.Never;
  }

  get startDate() {
    const { dtstart } = this.rruleOptions;
    return (new Date(dtstart)).toISOString()
      .substring(0, 10);
  }

  set startDate(date) {
    this.rruleOptions.dtstart = new Date(date);
  }

  get endDate() {
    const { until } = this.rruleOptions;
    return until ? (new Date(until)).toISOString().substring(0, 10)
      : this.rruleOptions.dtstart.toISOString().substring(0, 10);
  }

  set endDate(date) {
    let end = date ? new Date(date) : null;
    if (end && end < this.rruleOptions.dtstart) {
      end = this.rruleOptions.dtstart;
    }
    this.rruleOptions.until = end;
  }

  get byWeekDay() {
    return this.rruleOptions.byweekday ?? [];
  }

  set byWeekDay(weekdays: Array<number>) {
    this.rruleOptions.byweekday = weekdays;
  }

  get byMonthDay() {
    return this.rruleOptions.bymonthday ?? [];
  }

  set byMonthDay(monthdays: Array<number>) {
    this.rruleOptions.bymonthday = monthdays;
  }

  get byMonth() {
    return this.rruleOptions.bymonthday ?? [];
  }

  set byMonth(months: Array<number>) {
    this.rruleOptions.bymonth = months;
  }

  get count() {
    return this.rruleOptions.count ?? 1;
  }

  set count(val: number | null) {
    this.rruleOptions.count = val;
  }

  get modalTab() {
    if (this.selectedTab === TabItem.Location) {
      return 'ScenarioElementModalLocation';
    }
    if (this.selectedTab === TabItem.Schedule) {
      return 'ScenarioElementModalSchedule';
    }
    return 'ScenarioElementModalGraph';
  }

  get modalTabAttrs() {
    if (this.selectedTab === TabItem.Location) {
      return {
        coordinates: this.coordinates,
      };
    }
    if (this.selectedTab === TabItem.Schedule) {
      return {
        astronomicalControlEnabled: this.scenarioElementData.astronomical_control,
        byWeekDay: this.byWeekDay,
        byMonthDay: this.byMonthDay,
        interval: this.rruleOptions.interval,
        freq: this.rruleOptions.freq,
        bymonth: this.byMonth,
        startDate: this.startDate,
        endDate: this.endDate,
        count: this.count,
        selectedEndDateOption: this.selectedEndDateOption,
      };
    }
    return {
      sunriseSunsetRanges: this.sunriseSunsetRanges,
      changes: this.graph.changes,
    };
  }

  get modalTabEventHandlers() {
    if (this.selectedTab === TabItem.Location) {
      return {
        'update:coordinates': (val) => {
          this.coordinates = val;
        },
      };
    }
    if (this.selectedTab === TabItem.Schedule) {
      return {
        'update:astronomicalControlEnabled': (val) => {
          this.scenarioElementData.astronomical_control = val;
        },
        'update:byWeekDay': (val) => {
          this.byWeekDay = val;
        },
        'update:byMonthDay': (val) => {
          this.byMonthDay = val;
        },
        'update:byMonth': (val) => {
          this.byMonth = val;
        },
        'update:interval': (val) => {
          this.rruleOptions.interval = val;
        },
        'update:freq': (val) => {
          this.rruleOptions.freq = val;
        },
        'update:startDate': (val) => {
          this.startDate = val;
        },
        'update:endDate': (val) => {
          this.endDate = val;
        },
        'update:count': (val) => {
          this.count = val;
        },
      };
    }
    return {
      'update:changes': (val) => {
        this.graph.changes = val;
      },
    };
  }

  @Watch('startDate')
  onStartDateChange() {
    if (this.rruleOptions.until && (this.rruleOptions.until < this.rruleOptions.dtstart)) {
      this.rruleOptions.until = this.rruleOptions.dtstart;
    }
    // this.getSunriseSunsetRanges();
  }

  @Watch('rruleOptions', { deep: true })
  onRruleOptionsChange() {
    this.getSunriseSunsetRanges();
  }

  @Watch('coordinates')
  onLocationChange() {
    this.getSunriseSunsetRanges();
  }

  async getSunriseSunsetRanges() {
    if (!this.coordinates) return;

    const { dtstart } = this.rruleOptions;
    const startDate = dtstart.toISOString();
    let endDate: any;

    if (!this.rruleOptions.until && !this.rruleOptions.count) {
      const year = dtstart.getFullYear();
      const month = dtstart.getMonth();
      const day = dtstart.getDate();
      endDate = new Date(year + 1, month, day).toISOString();
    } else {
      const allOccurences = new RRule(this.rruleOptions).all();
      endDate = allOccurences[allOccurences.length - 1].toISOString();
    }

    this.sunriseSunsetRanges = await fetchSunriseSunsetRanges({
      lat: this.coordinates.lat,
      lng: this.coordinates.lng,
      from: startDate,
      to: endDate
    });
  }

  save() {
    this.saveClicked = true;
    const rrule = new RRule(this.rruleOptions).toString()
      .replace('↵', '\n').replaceAll(/[T][0-9]+/g, 'T000000');
    const scenarioElement = {
      ...this.scenarioElementData,
      rrule
    };
    // TODO temp JS number fix
    this.graph.changes = this.graph.changes.map((x) => Math.round(x));
    if (this.scenarioElementData.id && this.graph.id) {
      this.updateGraph(this.graph)
        .then(() => {
          this.updateScenarioElement(scenarioElement as Models.ScenarioElements.Model)
            .then(() => {
              this.setSelectedScenarioElementEditID(null);
              this.$emit('update:showModal', false);
              this.fetchScenarioElementsByScenario({ uuid: this.selectedScenarioUuid! });
            });
        })
        .catch(() => {
          this.saveClicked = false;
        });
    } else {
      this.createGraph(this.graph).then((graph) => {
        if (graph) {
          this.createScenarioElement({
            ...scenarioElement,
            graph_id: graph.uuid!
          } as Models.ScenarioElements.Model)
            .then(() => {
              this.setSelectedScenarioElementEditID(null);
              this.$emit('update:showModal', false);
              this.fetchScenarioElementsByScenario({ uuid: this.selectedScenarioUuid! });
            })
            .catch(() => {
              this.saveClicked = false;
            });
        }
      });
    }
  }

  cancel() {
    this.$emit('update:showModal', false);
  }

  back() {
    this.selectedTab -= 1;
  }

  next() {
    this.selectedTab += 1;
  }

  selectTab(tabNumber: number) {
    for (let t = 0; t < tabNumber; t += 1) {
      if (!this.isModalFormTabValid(t)) {
        return;
      }
    }
    this.selectedTab = tabNumber;
  }

  isModalFormTabValid(tabNumber: number) {
    switch (tabNumber) {
      case 0:
        return !!this.coordinates;
      case 1:
        return !!this.sunriseSunsetRanges;
      default:
        return true;
    }
  }

  mounted() {
    if (this.selectedScenarioElementEditID) {
      const scenarioElement = this.getScenarioElementById(this.selectedScenarioElementEditID);
      if (!scenarioElement) return;
      this.scenarioElementData = JSON.parse(JSON.stringify(scenarioElement));
      this.rruleOptions = rrulestr(this.scenarioElementData.rrule!).options;
      const graph = this.getGraph(scenarioElement.graph_id);
      if (graph) {
        this.graph = JSON.parse(JSON.stringify(graph));
      }
    } else {
      this.scenarioElementData.scenario_id = this.selectedScenarioUuid!;
    }
  }
}
