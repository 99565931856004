











































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DevicesAction, DevicesActionType, DevicesState } from '@/store/modules/devices/types';
import { CircuitsGetter, CircuitsGetterType } from '@/store/modules/circuits/types';
import { AppState } from '@/store/modules/app/types';
import {
  ScenariosAction,
  ScenariosActionType,
  ScenariosGetter,
  ScenariosGetterType,
  ScenariosState
} from '@/store/modules/scenarios/types';
import { Models } from '@mtap-smartcity/api';
import { isLamp } from '@/utils/type_check';
import { sort } from '@/utils/sort';
import { nameFilter } from '@/utils/name_filter';
import AssignAllCheckbox from '../AssignAllElements/AssignAllCheckbox/AssignAllCheckbox.vue';
import AssignAllModal from '../AssignAllElements/AssignAllModal/AssignAllModal.vue';

const devices = namespace('devices');
const circuits = namespace('circuits');
const scenarios = namespace('scenarios');
const app = namespace('app');

@Component({
  components: {
    AssignAllCheckbox,
    AssignAllModal
  }
})
export default class AssignTableLamps extends Vue {
  @Prop(String) readonly searchPhrase!: string

  @Prop(Boolean) readonly permissionWrite!: boolean

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @devices.State
  devices!: DevicesState['devices']

  @scenarios.State
  scenarios!: ScenariosState['scenarios']

  @scenarios.State
  scenarioElements!: ScenariosState['scenarioElements'];

  @scenarios.State
  selectedScenarioID!: ScenariosState['selectedScenarioID'];

  @scenarios.State
  selectedScenarioUuid!: ScenariosState['selectedScenarioUuid'];

  @scenarios.State
  selectedScenarioHasConnections!: ScenariosState['selectedScenarioHasConnections'];

  @circuits.Getter(CircuitsGetter.GetCircuit)
  getCircuit!: CircuitsGetterType['GET_CIRCUIT'];

  @scenarios.Getter(ScenariosGetter.GetScenario)
  getScenario!: ScenariosGetterType['GET_SCENARIO'];

  @scenarios.Getter(ScenariosGetter.GetScenarioById)
  getScenarioById!: ScenariosGetterType['GET_SCENARIO_BY_ID'];

  @devices.Action(DevicesAction.AddDeviceToScenario)
  addDeviceToScenario!: DevicesActionType['ADD_DEVICE_TO_SCENARIO'];

  @scenarios.Action(ScenariosAction.SetSelectedScenarioHasConnections)
  SetSelectedScenarioHasConnections!: ScenariosActionType['SET_SELECTED_SCENARIO_HAS_CONNECTIONS'];

  featureReady: boolean = false;

  allChecked: boolean = false;

  allUnchecked: boolean = false;

  intervalID: any = null;

  dialog: boolean = false;

  modalAssignAll: boolean | null = null;

  get isDefaultScenario() {
    const isDefaultUuid = this.scenarios.find((s) => s.is_default)?.uuid;
    if (this.selectedScenarioUuid === isDefaultUuid) return true;
    return false;
  }

  get sortedLamps() {
    let result = this.devices.filter(isLamp).sort((a, b) => sort(a.object_id, b.object_id));
    if (this.selectedScenarioID) {
      result = result.sort((a, b) => {
        if (a.scenario_id === this.selectedScenarioID && b.scenario_id !== this.selectedScenarioID) {
          return -1;
        }
        return 1;
      });
    }
    return result.filter((d) => nameFilter(d.object_id, this.searchPhrase));
  }

  openModal(checkAllSelected: boolean) {
    this.modalAssignAll = checkAllSelected;
    this.dialog = true;
  }

  discardSubmit() {
    this.dialog = false;
  }

  async confirmSubmit(assignAll: boolean) {
    this.dialog = false;
    await this.addAllToScenario(assignAll);
    this.clearAllCheckbox();
  }

  circuitName(lamp: Models.Devices.Lamp) {
    const { circuit_id } = lamp;
    if (!circuit_id) {
      return '';
    }
    const { name } = this.getCircuit(circuit_id) ?? { name: '' };
    return name;
  }

  circuitColor(lamp: Models.Devices.Lamp) {
    const { circuit_id } = lamp;
    if (!circuit_id) return '';
    const { color } = this.getCircuit(circuit_id) ?? { color: '' };
    return color;
  }

  scenarioName(lamp: Models.Devices.Lamp) {
    const { scenario_id } = lamp;
    if (!scenario_id) {
      return '';
    }
    const { name } = this.getScenario(scenario_id) ?? { name: '' };
    return name;
  }

  belongsToSelectedScenario(lamp: Models.Devices.Lamp) {
    if (lamp.scenario_id === this.selectedScenarioUuid) {
      if (!this.selectedScenarioHasConnections) this.SetSelectedScenarioHasConnections(true);
      return true;
    }
    return false;
  }

  checkAll() {
    clearInterval(this.intervalID);
    this.intervalID = setInterval(() => { this.allChecked = !this.allChecked; }, 700);
  }

  uncheckAll() {
    this.allChecked = false;
    clearInterval(this.intervalID);
    this.intervalID = setInterval(() => { this.allUnchecked = !this.allUnchecked; }, 700);
  }

  clearAllCheckbox() {
    clearInterval(this.intervalID);
    this.intervalID = null;
    this.allChecked = false;
    this.allUnchecked = false;
  }

  addAllToScenario(assignAll: boolean) {
    const lampsToAssign = this.sortedLamps.filter(
      (l: Models.Devices.Lamp) => (
        assignAll ? !this.belongsToSelectedScenario(l) : this.belongsToSelectedScenario(l))
    );
    console.log(
      `With this action you would ${
        assignAll
          ? 'assign'
          : 'unassign'
      } following devices:`, lampsToAssign
    );
    this.clearAllCheckbox();
  }

  addToScenario(lamp: Models.Devices.Lamp, switchLoader: () => void) {
    if (this.isDefaultScenario) return;
    switchLoader();
    const isDefaultUuid = this.scenarios.find((s) => s.is_default)?.uuid;
    const scenarioUuidToBeAssigned = this.belongsToSelectedScenario(lamp) ? isDefaultUuid : this.selectedScenarioUuid;
    const scenarioToBeAssigned = scenarioUuidToBeAssigned ? this.getScenario(scenarioUuidToBeAssigned) : null;

    const selectedScenarioName = this.scenarioName(lamp);
    const scenarioName = scenarioToBeAssigned?.is_default ? selectedScenarioName : scenarioToBeAssigned?.name;
    const modifiedLamp = {
      ...lamp,
      scenario_id: scenarioUuidToBeAssigned
    };
    const assigningToSelectedScenario = scenarioUuidToBeAssigned === this.selectedScenarioUuid;

    this.addDeviceToScenario({
      device: modifiedLamp,
      scenarioName: scenarioName ?? null,
      assignToDefault: scenarioToBeAssigned?.is_default ?? true
    })
      .then(() => {
        this.SetSelectedScenarioHasConnections(assigningToSelectedScenario);
      })
      .finally(() => {
        switchLoader();
      });
  }
}
