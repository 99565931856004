<template>
  <v-row class="l-scenarios-tab">
    <v-col cols="12" sm="4" class="l-scenarios-tab__col pa-4 pr-2">
      <ScenariosList />
    </v-col>
    <v-col cols="12" sm="8" class="l-scenarios-tab__col pa-4 pl-2">
      <PlanningCardRightSidePanel/>
    </v-col>
  </v-row>
</template>

<script>
import ScenariosList from './ScenariosList/ScenariosList.vue';
import PlanningCardRightSidePanel from './PlanningCardRightSidePanel/PlanningCardRightSidePanel.vue';

export default {
  name: 'PlanningCardScenariosTab',
  components: {
    ScenariosList,
    PlanningCardRightSidePanel
  },
};
</script>

<style lang="scss" scoped>
.l-scenarios-tab {
  min-height: 0;
  &__col {
    height: 100%;
  }
}
</style>
