import {
  axisBottom, axisLeft
} from 'd3';
import timeFromIndex from './date-format';
import { width, height } from './config';

function xAxis(xScale) {
  return function axis(g) {
    return g
      .attr('transform', `translate(0, ${height})`)
      .call(
        axisBottom(xScale)
          .ticks(8)
          .tickFormat((i) => timeFromIndex(i))
      )
      .call(
        (g1) => g1
          .selectAll('.tick text')
          .style('font-size', '8px')
      )
      .call(
        (g1) => g1
          .select('.domain')
          .style('stroke', 'none')
      );
  };
}

const tickFormat = (d) => {
  if (!(d % 20)) {
    return `${d}%`;
  }
  return '';
};

function yAxis(yScale) {
  return function axis(g) {
    return g
      .call(
        axisLeft(yScale)
          .tickSize(0)
          .tickFormat(tickFormat)
          .tickPadding(10)
      )
      .call(
        (g1) => g1
          .selectAll('.tick:not(:first-of-type) line')
          .attr('stroke-opacity', 0.5)
          .attr('stroke-dasharray', '2,2')
      )
      .call(
        (g1) => g1
          .select('.domain')
          .remove()
      )
      .call(
        (g1) => g1
          .selectAll('.tick line')
          .clone()
          .attr('x2', width)
          .attr('stroke-opacity', 0.07)
      )
      .call(
        (g1) => g1
          .selectAll('.tick text')
          .attr('fill', '#ff5a00')
          .style('font-size', '8px')
      );
  };
}

export {
  xAxis,
  yAxis
};
