





















import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

export interface ITabItem {
  value: number,
  name: TranslateResult,
  mdiIcon: string
}

export default Vue.extend({
  props: {
    tabs: {
      type: Array as PropType<Array<ITabItem>>,
      required: true,
    },
    selectedTab: {
      type: Number,
      required: true
    }
  }
});
