



































import {
  Component, Vue, Ref, Watch
} from 'vue-property-decorator';

@Component
export default class ScenarioElementModalLocation extends Vue {
  @Ref() modalMapRef!: any

  modalMap: google.maps.Map | null = null;

  locationId = ''

  mapCenter = { lat: 53.826673, lng: 22.361822 }

  locationCoordinates: google.maps.LatLngLiteral | null = null

  search = ''

  searchResults: Array<{ text: string, value: string }> = []

  autocompleteService!: google.maps.places.AutocompleteService

  geocoder!: google.maps.Geocoder

  mapOptions = {
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: true,
    fullscreenControl: false,
    disableDefaultUi: false,
  } as google.maps.MapOptions;

  // mapsInit() {
  //   this.autocompleteService = new window.google.maps.places.AutocompleteService();
  // }

  @Watch('search')
  onSearchChange(newVal) {
    if (newVal) {
      this.autocompleteService.getPlacePredictions({
        input: newVal,
        types: ['address'],
        componentRestrictions: {
          country: 'pl'
        },
      }, this.placeSuggestions);
    }
  }

  @Watch('locationId')
  onLocationIdChange(newVal) {
    if (newVal) {
      this.geocoder.geocode({ placeId: newVal }, this.placeCoordinates);
    }
  }

  selectLocation(event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) {
    const { latLng } = event;
    if (this.modalMap) {
      this.geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK') {
          const { place_id, formatted_address } = results[0];
          this.searchResults = [{
            text: formatted_address,
            value: place_id
          }];
          this.locationId = place_id;
          this.locationCoordinates = latLng.toJSON();
        }
      });
    }
  }

  locationDescription(results: Array<google.maps.GeocoderResult>, status: google.maps.GeocoderStatus) {
    if (status === 'OK') {
      const { formatted_address } = results[0];
      this.autocompleteService.getPlacePredictions({ input: formatted_address }, this.placeSuggestions);
    }
  }

  placeSuggestions(predictions: Array<google.maps.places.AutocompletePrediction>, status: google.maps.places.PlacesServiceStatus) {
    if (status !== 'OK') {
      this.searchResults = [];
    } else {
      this.searchResults = predictions.map((p) => ({
        text: p.description,
        value: p.place_id
      }));
    }
  }

  placeCoordinates(result: Array<google.maps.GeocoderResult>, status: google.maps.GeocoderStatus) {
    if (status === 'OK') {
      // const latLng = result.geometry?.location;
      const latLng = result[0].geometry?.location;
      if (this.modalMap && latLng) {
        this.modalMap.panTo(latLng);
        const latLngLiteral = latLng.toJSON();
        this.locationCoordinates = latLngLiteral;
        this.$emit('update:coordinates', latLngLiteral);
      }
    }
  }

  mounted() {
    this.modalMapRef.$mapPromise.then((map) => {
      this.modalMap = map;
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.geocoder = new window.google.maps.Geocoder();
      map.panTo({ lat: 53.826673, lng: 22.361822 });
    });
  }
}
