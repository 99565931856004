import { line, curveStepAfter } from 'd3';

export default function chartLine(container, data, xScale, yScale, eventHandlers) {
  const {
    mousemove, mouseover, mouseout, onclick
  } = eventHandlers;

  const stepLine = line()
    .curve(curveStepAfter)
    .x((_, i) => xScale(i))
    .y((d) => yScale(d));

  const selection = container.select('.step-path');

  if (selection.empty()) {
    container.append('path')
      .datum(data)
      .attr('class', 'step-path')
      .attr('fill', 'none')
      .attr('stroke', '#ed8611')
      .attr('stroke-width', 1)
      .attr('d', stepLine)
      .style('pointer-events', 'all')
      .on('mouseover', mouseover)
      .on('mouseout', mouseout)
      .on('mousemove', mousemove)
      .on('click', onclick);
  } else {
    selection
      .datum(data)
      .attr('d', stepLine);
  }
}
