export default function timeFromIndex(index) {
  const minutes = index * 15;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes - (hours * 60);
  let hoursStr = hours.toString();
  let minutesStr = remainingMinutes.toString();
  if (hoursStr.length < 2) {
    hoursStr = '0'.concat(hoursStr);
  }
  if (minutesStr.length < 2) {
    minutesStr = '0'.concat(minutesStr);
  }
  return `${hoursStr}:${minutesStr}`;
}
