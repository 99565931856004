
































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AssignTableGroups from './AssignTableGroups/AssignTableGroups.vue';
import AssignTableCircuits from './AssignTableCircuits/AssignTableCircuits.vue';
import AssignTableLamps from './AssignTableLamps/AssignTableLamps.vue';
import AssignRulesList from './AssignRulesList/AssignRulesList.vue';
import ScenarioElementModal from './ScenarioElementModal/ScenarioElementModal.vue';
import { ScenariosAction, ScenariosActionType, ScenariosState } from '@/store/modules/scenarios/types';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

enum ScenariosTabs {
  Rules = 'rules',
  Groups = 'groups',
  Circuits = 'circuits',
  Lamps = 'lamps',
}

const scenarios = namespace('scenarios');
const admin = namespace('admin');

@Component({
  components: {
    AssignRulesList,
    AssignTableGroups,
    AssignTableCircuits,
    AssignTableLamps,
    ScenarioElementModal,
  }
})
export default class PlanningCardRightSidePanel extends Vue {
  selectedTab: ScenariosTabs = ScenariosTabs.Rules

  get tabs() {
    return [
      { name: this.$tc('main.scenarioRule', 2), value: ScenariosTabs.Rules },
      { name: this.$tc('main.group', 2), value: ScenariosTabs.Groups },
      { name: this.$tc('main.circuit', 2), value: ScenariosTabs.Circuits },
      { name: this.$tc('main.lamp', 2), value: ScenariosTabs.Lamps },
    ];
  }

  searchPhrase = ''

  showModal = false

  @scenarios.State
  scenarios!: ScenariosState['scenarios']

  @scenarios.State
  selectedScenarioUuid!: ScenariosState['selectedScenarioUuid']

  @scenarios.State
  selectedScenarioElementID!: ScenariosState['selectedScenarioElementEditID']

  @scenarios.State
  selectedScenarioID!: ScenariosState['selectedScenarioID']

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @scenarios.Action(ScenariosAction.FetchScenarioElementsByScenario)
  fetchScenarioElementsByScenario!: ScenariosActionType['FETCH_SCENARIO_ELEMENTS_BY_SCENARIO'];

  @scenarios.Action(ScenariosAction.SetSelectedScenarioHasConnections)
  SetSelectedScenarioHasConnections!: ScenariosActionType['SET_SELECTED_SCENARIO_HAS_CONNECTIONS'];

  @Watch('selectedScenarioElementID')
  onSelectedScenarioElementIDChange(scenarioElementId: number) {
    if (scenarioElementId) this.showModal = true;
  }

  get isDefaultScenario() {
    const isDefaultUuid = this.scenarios.find((s) => s.is_default)?.uuid;
    if (this.selectedScenarioUuid === isDefaultUuid) return true;
    return false;
  }

  get isScenarioSelected() {
    return !!this.selectedScenarioID;
  }

  get writePermission() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.write;
  }

  get deletePermission() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.delete;
  }

  addScenarioElement() {
    this.showModal = true;
  }

  @Watch('selectedScenarioUuid')
  onSelectedScenarioUuidChange() {
    this.SetSelectedScenarioHasConnections(null);
  }
}
