





import {
  Component, Vue, Watch, Prop
} from 'vue-property-decorator';
import * as d3 from 'd3';
import { Models } from '@mtap-smartcity/api';
import {
  margin, height, width, astroBarBottomMargin, astroBarHeight
} from './features/config';
import { xAxis, yAxis } from './features/axes';
import timeFromIndex from './features/date-format';
import generateAstroHelpers from './features/astro-bars';
import chartLine from './features/line';
import chartArea from './features/area';
import chartPoints from './features/chart-points';

@Component
export default class ScenarioElementChart extends Vue {
  @Prop({ type: Array, required: true }) changes!: Models.ScenarioGraphs.Model['changes']

  @Prop({ type: Object, required: true }) sunriseSunsetRanges!: Models.Scenarios.SunriseSunsetRange

  @Prop({ type: Array, required: true }) graphRules!: Models.ScenarioGraphs.Rule[]

  newRule: Models.ScenarioGraphs.Rule | null = null

  astroGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any> | null = null

  chartGroup: d3.Selection<SVGGElement, unknown, HTMLElement, any> | null = null

  tooltip: d3.Selection<SVGGElement, unknown, HTMLElement, any> | null = null

  dutyLevel: d3.Selection<SVGTextElement, unknown, HTMLElement, any> | null = null

  xScale = d3
    .scaleLinear()
    .domain([0, 95])
    .range([0, width]);

  yScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([height, 0]);

  @Watch('sunriseSunsetRanges', { deep: true })
  onSunriseSunsetRangesChange(newRanges: Models.Scenarios.SunriseSunsetRange) {
    if (!this.astroGroup) return;
    this.astroGroup.select('.astrohelpers').remove();
    this.astroGroup.select('defs').remove();
    generateAstroHelpers(this.astroGroup, newRanges);
  }

  @Watch('graphRules', { deep: true })
  graphRulesChange() {
    if (!this.chartGroup) return;
    chartLine(this.chartGroup, this.changes, this.xScale, this.yScale, this.mouseMoveHandlers());
    chartArea(this.chartGroup, this.changes, this.xScale, this.yScale, this.mouseMoveHandlers());
    chartPoints(this.chartGroup, this.graphRules, this.xScale, this.yScale, this.circleEventHandlers());
  }

  circleEventHandlers() {
    const that = this;

    function dragstarted(this: Element) {
      d3.select(this).classed('active', true);
      that.dutyLevel!.style('display', null);
    }

    function dragged(this: Element, event: DragEvent) {
      let { y } = event;

      // y = Math.min(Math.max(y, 0), height);
      if (y < 0) {
        y = 0;
      }
      if (y > height) {
        y = height;
      }

      d3.select(this).attr('cy', y);

      const duty = Math.floor(that.yScale.invert(y));
      const offset = +d3.select(this).attr('offset');
      that.$emit('modifyChanges', { offset, duty });

      that.dutyLevel!
        .attr('transform', `translate(${that.xScale(offset)},${that.yScale(duty)})`)
        .text(duty);
    }

    function dragended(this: Element) {
      d3.select(this).classed('active', false);
      that.dutyLevel!.style('display', 'none').text('');
    }

    function ondoubleclick(this: Element) {
      const offset = +d3.select(this).attr('offset');
      that.$emit('removeRule', offset);
    }

    return {
      dragstarted,
      dragged,
      dragended,
      ondoubleclick
    };
  }

  mouseMoveHandlers() {
    const that = this;

    function mousemove(event: MouseEvent) {
      if (!that.tooltip) return;
      const xPos = d3.pointer(event)[0];
      const x0 = that.xScale.invert(xPos);
      const i = Math.floor(x0);
      const duty = that.changes[i];

      that.tooltip.select('.tooltip-point')
        .attr('transform', `translate(${that.xScale(i)},${that.yScale(duty)})`);

      function isOffsetPoint(idx: number) {
        return that.graphRules.findIndex((o) => o.offset === idx) !== -1;
      }

      if (isOffsetPoint(i)) {
        that.newRule = null;
        that.tooltip.select('.tooltip-point').style('display', 'none');
      } else {
        that.newRule = { offset: i, duty };
        that.tooltip.select('.tooltip-point').style('display', null);
      }

      that.tooltip.select('.tooltip-hour')
        .attr('transform', `translate(${that.xScale(i)},${that.yScale(duty)})`)
        .text(timeFromIndex(i));

      that.tooltip.select('line.tooltip-time')
        .attr('y2', that.yScale(duty))
        .attr('x1', that.xScale(i))
        .attr('x2', that.xScale(i));

      that.tooltip.select('line.tooltip-mileage')
        .attr('y1', that.yScale(duty))
        .attr('y2', that.yScale(duty));
    }

    function mouseover() {
      if (!that.tooltip) return;
      that.tooltip.style('display', null);
    }

    function mouseout() {
      if (!that.tooltip) return;
      that.tooltip.style('display', 'none');
    }

    function onclick() {
      if (that.newRule) {
        that.$emit('addNewRule', that.newRule);
        // chartPoints(that.chartGroup, that.graphRules, that.xScale, that.yScale, that.circleEventHandlers());
      }
    }

    return {
      mousemove,
      mouseover,
      mouseout,
      onclick
    };
  }

  mounted() {
    // add responsive svg and append group
    const svg = d3
      .select('.l-graph-container')
      .append('svg')
      .attr('width', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${height + margin.bottom + margin.top}`
      )
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top - astroBarHeight - astroBarBottomMargin})`);

    this.astroGroup = svg
      .append('g')
      .attr('class', 'astro');

    this.chartGroup = svg
      .append('g')
      .attr('class', 'chart')
      .attr('transform', `translate(0, ${astroBarHeight + astroBarBottomMargin})`);

    generateAstroHelpers(this.astroGroup, this.sunriseSunsetRanges);

    // APPEND X-AXIS
    this.chartGroup.append('g')
      .attr('class', 'xAxis')
      .attr('clip-path', 'url(#axis-clip)')
      .call(xAxis(this.xScale));

    // APPEND Y-AXIS
    this.chartGroup.append('g')
      .attr('class', 'yAxis')
      .call(yAxis(this.yScale));

    //  APPEND TOOLTIP
    this.tooltip = this.chartGroup.append('g')
      .attr('class', 'tooltip')
      .style('display', 'none');

    this.tooltip.append('circle')
      .attr('class', 'tooltip-point')
      .attr('fill', '#ff5a00')
      .attr('r', 3);

    this.tooltip.append('text')
      .attr('class', 'tooltip-hour')
      .attr('text-anchor', 'middle')
      .attr('font-size', '8px')
      .attr('fill', '#ff5a00')
      .attr('opacity', 0.6)
      .attr('dy', '-.75em');

    this.tooltip.append('line')
      .attr('class', 'tooltip-mileage')
      .attr('stroke', '#ff5a00')
      .attr('stroke-opacity', 0.5)
      .attr('stroke-dasharray', '2,2')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height)
      .attr('y2', height);

    this.tooltip.append('line')
      .attr('class', 'tooltip-time')
      .attr('stroke', '#ff5a00')
      .attr('stroke-opacity', 0.5)
      .attr('stroke-dasharray', '2,2')
      .attr('y1', height)
      .attr('y2', height);

    // APPEND DUTY LEVEL
    this.dutyLevel = this.chartGroup.append('text')
      .attr('class', 'duty-level')
      .attr('text-anchor', 'left')
      .attr('font-size', '8px')
      .attr('fill', '#1a3c66')
      .attr('opacity', 0.6)
      .attr('dy', '-.55em')
      .attr('dx', '0.4em');

    // this.updateChart();
    chartLine(this.chartGroup, this.changes, this.xScale, this.yScale, this.mouseMoveHandlers());
    chartArea(this.chartGroup, this.changes, this.xScale, this.yScale, this.mouseMoveHandlers());
    chartPoints(this.chartGroup, this.graphRules, this.xScale, this.yScale, this.circleEventHandlers());
  }
}
