import { area, curveStepAfter } from 'd3';
import { height } from './config';

export default function chartArea(container, data, xScale, yScale, eventHandlers) {
  const {
    mousemove, mouseover, mouseout, onclick
  } = eventHandlers;

  const stepArea = area()
    .curve(curveStepAfter)
    .x((_, i) => xScale(i))
    .y0(height)
    .y1((d) => yScale(d));

  const selection = container.select('.area');

  if (selection.empty()) {
    container
      .append('path')
      .datum(data)
      .attr('class', 'area')
      .attr('fill', '#ed8611')
      .attr('fill-opacity', 0.3)
      .attr('stroke', 'none')
      .attr('d', stepArea)
      .style('pointer-events', 'all')
      .style('cursor', 'pointer')
      .on('mouseover', mouseover)
      .on('mouseout', mouseout)
      .on('mousemove', mousemove)
      .on('click', onclick);
  } else {
    selection
      .datum(data)
      .attr('d', stepArea);
  }
}
