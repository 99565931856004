import {
  timeFormat, timeParse, scaleTime, timeDay
} from 'd3';
import {
  astroBarHeight, astroBarBottomMargin, height, width
} from './config';

export default function generateAstroHelpers(container, sunriseSunsetRanges) {
  const formatTime = timeFormat('%H:%M');
  const parseTime = timeParse('%H:%M');
  const ranges = { ...sunriseSunsetRanges };
  const midnight = parseTime('00:00');
  const minAstroHelperWidth = 4;

  Object.keys(ranges).forEach((key) => {
    ranges[key] = parseTime(formatTime(new Date(ranges[key])));
  });

  const {
    maxSunrise, maxSunset, minSunrise, minSunset
  } = ranges;

  const data = [
    { type: 'sunrise', min: minSunrise, max: maxSunrise },
    { type: 'sunset', min: minSunset, max: maxSunset },
  ];

  const xScaleAstro = scaleTime()
    .domain([midnight, timeDay.offset(midnight, 1)])
    .range([0, width]);

  function astroHelperWidth(d) {
    return Math.max(xScaleAstro(d.max) - xScaleAstro(d.min), minAstroHelperWidth);
  }

  function astroHelperStartingPoint(d) {
    if (astroHelperWidth(d) <= minAstroHelperWidth) return xScaleAstro(d.min) - minAstroHelperWidth / 2;
    return xScaleAstro(d.min);
  }

  // astro helpers group
  const astroGroup = container
    .append('g')
    .attr('class', 'astrogroup')
    .attr('transform', `translate(0, ${astroBarBottomMargin - astroBarHeight})`)
    .attr('clip-path', 'url(#chart-clip)');

  // astro bar linear gradient
  const astroBarGradient = astroGroup
    .append('defs')
    .append('linearGradient')
    .attr('id', 'astroBarGradient')
    .attr('x1', '0%')
    .attr('x2', '100%')
    .attr('spreadMethod', 'pad');

  astroBarGradient.append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#24487e')
    .attr('stop-opacity', 1);

  astroBarGradient.append('stop')
  // .attr('offset', sunriseOffset / width)
    .attr('offset', xScaleAstro(maxSunrise) / width)
    .attr('stop-color', '#ffc200')
    .attr('stop-opacity', 1);

  astroBarGradient.append('stop')
  // .attr('offset', sunsetOffset / width)
    .attr('offset', xScaleAstro(minSunset) / width)
    .attr('stop-color', '#ffc200')
    .attr('stop-opacity', 1);

  astroBarGradient.append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#24487e')
    .attr('stop-opacity', 1);

  // add horizontal gradient bar
  astroGroup.append('rect')
    .attr('class', 'gradient-rect')
    .attr('width', width)
    .attr('height', astroBarHeight)
    .attr('rx', 8)
    .style('fill', 'url(#astroBarGradient)');

  // sunrise/sunset bars
  const addAstroBar = (g) => {
    g.append('rect')
      .attr('class', 'astrobar-rect')
      .attr('x', (d) => astroHelperStartingPoint(d))
      .attr('y', astroBarHeight)
      .attr('width', (d) => astroHelperWidth(d))
      .attr('height', height + astroBarHeight)
      .attr('rx', (d) => astroHelperWidth(d) / 2)
      .style('fill', '#ed8611')
      .style('opacity', 0.3);

    g.append('image')
      .attr('class', 'sun-icon')
      .attr('height', 12)
      .attr('width', 12)
      .attr('xlink:href', (d) => `/${d.type}.svg`)
      .attr('x', (d) => astroHelperStartingPoint(d) + astroHelperWidth(d) / 2 - 6)
      .attr('y', 2);
    return g;
  };

  astroGroup
    .selectAll('.astrobar')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'astrobar')
    .call(addAstroBar);
}
