import { drag } from 'd3';

export default function chartPoints(container, data, xScale, yScale, eventHandlers) {
  const {
    dragstarted, dragged, dragended, ondoubleclick
  } = eventHandlers;

  const dragBehavior = drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended);

  const selection = container
    .selectAll('.circle')
    .data(data, (d) => d.offset);

  selection.exit()
    .transition()
    .duration(300)
    .attr('r', 0)
    .remove();

  selection
    .enter()
    .append('circle')
    .attr('class', 'circle')
    .attr('fill', '#1a3c66')
    .attr('stroke', 'none')
    .attr('r', 0)
    .style('cursor', 'pointer')
    .on('dblclick', ondoubleclick)
    .call(dragBehavior)
    .merge(selection)
    .attr('cx', (d) => xScale(d.offset))
    .attr('cy', (d) => yScale(d.duty))
    .attr('offset', (d) => d.offset)
    .transition()
    .duration(300)
    .attr('r', 3)
    .attr('idx', (d, i) => i);
}
