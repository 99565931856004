



























import ScenariosListItem from './ScenariosListItem/ScenariosListItem.vue';
import { namespace } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Models } from '@mtap-smartcity/api';
import { AppState } from '@/store/modules/app/types';
import { ScenariosAction, ScenariosActionType, ScenariosState } from '@/store/modules/scenarios/types';
import { sort } from '@/utils/sort';
import { focusOnNewItemNameInput } from '@/utils/focus_on_input';
import { nameFilter } from '@/utils/name_filter';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const app = namespace('app');
const admin = namespace('admin');
const scenarios = namespace('scenarios');

@Component({
  components: {
    ScenariosListItem,
  },
})
export default class ScenariosList extends Vue {
  userEditingScenario = false;

  dialog = false;

  deleteData: Models.Scenarios.Model | null = null;

  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @scenarios.State
  scenarios!: ScenariosState['scenarios'];

  @scenarios.State
  selectedScenarioID!: ScenariosState['selectedScenarioID'];

  @scenarios.Action(ScenariosAction.FetchScenarios)
  fetchScenarios!: ScenariosActionType['FETCH_SCENARIOS'];

  @scenarios.Action(ScenariosAction.DeleteScenario)
  deleteScenario!: ScenariosActionType['DELETE_SCENARIO'];

  @scenarios.Action(ScenariosAction.AddScenarioPlaceholder)
  addScenarioPlaceholder!: ScenariosActionType['ADD_SCENARIO_PLACEHOLDER'];

  @scenarios.Action(ScenariosAction.SetSelectedScenarioID)
  setSelectedScenarioID!: ScenariosActionType['SET_SELECTED_SCENARIO_ID'];

  @scenarios.Action(ScenariosAction.SetSelectedScenarioUuid)
  setSelectedScenarioUuid!: ScenariosActionType['SET_SELECTED_SCENARIO_UUID'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get isPlaceholderScenarioInStore() {
    return this.scenarios.some((s) => !s.id);
  }

  get scenarioNamesArray() {
    const scenarioNames: string[] = [];
    this.scenarios.forEach((s: Models.Scenarios.Model) => {
      scenarioNames.push(s.name);
    });
    return scenarioNames;
  }

  get isLastScenario() {
    return this.scenarios.length < 2 || (this.scenarios.length < 3 && this.isPlaceholderScenarioInStore);
  }

  @Watch('userEditingScenario')
  onUserEditingScenario() {
    this.setSelectedScenarioID(null);
    this.setSelectedScenarioUuid(null);
  }

  filteredScenarios(searchPhrase: string) {
    if (this.scenarios.length <= 1) return this.scenarios;
    const sortedScenarios = [...this.scenarios].sort((a, b) => sort(a.name, b?.name));
    const indexOfDefault = sortedScenarios.findIndex((s) => s.is_default);
    if (indexOfDefault >= 0) {
      const defaultScenario: Models.Scenarios.Model = sortedScenarios.splice(indexOfDefault, 1)[0];
      sortedScenarios.unshift(defaultScenario);
    }
    return sortedScenarios.filter((s) => nameFilter(s.name, searchPhrase));
  }

  modalAction(scenario: Models.Scenarios.Model) {
    this.dialog = true;
    this.deleteData = scenario;
  }

  closeModal() {
    this.dialog = false;
    this.deleteData = null;
  }

  deleteModal() {
    this.deleteScenario(this.deleteData!);
    this.dialog = false;
    this.deleteData = null;
  }

  handleClick(scenario: Models.Scenarios.Model): void {
    if (this.userActionStatus.status === 'pending'
      || this.isPlaceholderScenarioInStore
      || this.userEditingScenario) {
      return;
    }
    if (this.selectedScenarioID && this.selectedScenarioID === scenario.id) {
      this.setSelectedScenarioID(null);
      this.setSelectedScenarioUuid(null);
    } else {
      this.setSelectedScenarioID(scenario.id!);
      this.setSelectedScenarioUuid(scenario.uuid!);
    }
  }

  addScenario() {
    if (!this.scenarios.some((s) => !s.id)) {
      this.setSelectedScenarioID(null);
      this.setSelectedScenarioUuid(null);
      this.addScenarioPlaceholder();
    }
    focusOnNewItemNameInput();
  }

  mounted() {
    if (!this.selectedScenarioID && this.scenarios.length) {
      const { id, uuid } = this.scenarios.filter((s) => !s.is_default).sort((a, b) => sort(a.name, b.name))[0];
      this.setSelectedScenarioID(id!);
      this.setSelectedScenarioUuid(uuid as string);
    }
  }

  destroyed() {
    this.setSelectedScenarioID(null);
    this.setSelectedScenarioUuid(null);
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'scenarios');
    return getPermissions.delete;
  }
}
