const astroBarHeight = 15;
const astroBarBottomMargin = 5;
const margin = {
  top: 20 + astroBarHeight + astroBarBottomMargin,
  right: 20,
  bottom: 20,
  left: 40,
};
const width = 400 - margin.left - margin.right;

const height = 200 - margin.top - margin.bottom;

export {
  astroBarHeight,
  astroBarBottomMargin,
  margin,
  width,
  height
};
