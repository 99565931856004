



















































































































import {
  Component, Vue, Ref, Prop, PropSync, Watch
} from 'vue-property-decorator';
import { RRule, Weekday } from 'rrule';
import WeekDaysPicker from './WeekDaysPicker.vue';
import { EndDateOption } from '../ScenarioElementModal.vue';

enum MonthlyRecOption {
  OnDate,
  OnWeekDay
}

@Component({
  components: {
    WeekDaysPicker,
  }
})
export default class ScenarioElementModalSchedule extends Vue {
  @Ref() scheduleForm!: any;

  @Prop({ required: true, type: Array }) readonly byWeekDay!: Array<number>

  @PropSync('interval') intervalSynced!: number

  @PropSync('freq') freqSynced!: any // temp

  @PropSync('startDate') startDateSynced!: string

  @Prop() readonly endDate!: string | null

  @PropSync('count') countSynced!: number | null

  @PropSync('astronomicalControlEnabled') astronomicalControlEnabledSynced!: boolean

  @Prop() readonly selectedEndDateOption!: EndDateOption

  selectedMonthlyOption = MonthlyRecOption.OnDate

  @Watch('freqSynced')
  onFreqSyncedChange(val: MonthlyRecOption) {
    this.$emit('update:byWeekDay', []);
    this.$emit('update:byMonthDay', []);
    this.$emit('update:byMonth', []);

    if (val === 1) {
      this.updateByMonthDay(); // set byMonthDay with default option
    }

    if (val === 0) {
      this.setYearlyOccurence();
    }
  }

  @Watch('selectedMonthlyOption')
  onSelectedMonthlyOptionChange() {
    this.updateByMonthDay();
  }

  get frequencies() {
    return [
      { text: this.$tc('main.day'), value: RRule.DAILY },
      { text: this.$tc('main.week'), value: RRule.WEEKLY },
      { text: this.$tc('main.month'), value: RRule.MONTHLY },
      { text: this.$tc('main.year'), value: RRule.YEARLY },
    ];
  }

  get monthlySelectOptions() {
    const date = new Date(this.startDateSynced);
    return [
      {
        text: this.$t('schedule.monthlyOnDate', {
          monthDay: date.getDate()
        }),
        value: MonthlyRecOption.OnDate
      },
      {
        text: this.$t('schedule.monthlyOnWeekDay', {
          weekNumber: this.weekNumber(date),
          weekDay: this.$t(`schedule.weekdays[${this.weekDayNumber(date)}][2]`)
        }),
        value: MonthlyRecOption.OnWeekDay
      },
    ];
  }

  weekNumber(date: Date) {
    return Math.ceil(date.getDate() / 7);
  }

  weekDayNumber(date) {
    return (date.getDay() + 6) % 7;
  }

  updateByWeekDay(weekday: number) {
    const selectedWeekdays = [...this.byWeekDay];
    const index = selectedWeekdays.indexOf(weekday);
    if (index === -1) {
      selectedWeekdays.push(weekday);
    } else {
      selectedWeekdays.splice(index, 1);
    }
    this.$emit('update:byWeekDay', selectedWeekdays);
  }

  updateByMonthDay() {
    const date = new Date(this.startDateSynced);

    if (this.selectedMonthlyOption === MonthlyRecOption.OnDate) {
      this.$emit('update:byMonthDay', [
        date.getDate()
      ]);
    } else {
      this.$emit('update:byMonthDay', []);
      this.$emit('update:byWeekDay', [
        new Weekday(this.weekDayNumber(date), this.weekNumber(date))
      ]);
    }
  }

  updateEndDateOption(val: EndDateOption) {
    if (val === EndDateOption.AfterRepeat) {
      this.$emit('update:count', 1);
      this.$emit('update:endDate', null);
    } else if (val === EndDateOption.OnDate) {
      this.$emit('update:endDate', this.endDate);
      this.$emit('update:count', null);
    } else {
      this.$emit('update:endDate', null);
      this.$emit('update:count', null);
    }
  }

  setYearlyOccurence() {
    this.$emit('update:byMonthDay', [new Date(this.startDateSynced).getDate()]);
    this.$emit('update:byMonth', [new Date(this.startDateSynced).getMonth() + 1]);
  }
}
