






































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AssignAllModal extends Vue { 
  @Prop({ type: Boolean, default: false }) readonly dialog!: boolean;

  @Prop({ type: Boolean, default: false }) readonly assignAll!: boolean;

  @Prop({ type: String, default: false }) readonly element!: string;

  get action() {
    return this.$t(`messages.${
      this.assignAll
        ? 'youAreAssigningAllElements'
        : 'youAreDetachingAllElements'
    }`, { element: this.$tc(`main.${this.element}`, 2) });
  }

  confirm() {
    this.$emit('confirm', this.assignAll);
  }

  discard() {
    this.$emit('discard');
  }
}
